var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "zg-section",
    { attrs: { background: "white", width: "xl", "extra-y-padding": "" } },
    [
      _c(
        "div",
        {
          class: [
            "faq",
            {
              "faq--with-sidebar": _vm.sidebar,
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "questions" },
            [
              _c("h3", [_vm._v(_vm._s(_vm.title))]),
              _vm._v(" "),
              _c("zg-faq", { attrs: { faqs: _vm.faqs } }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.sidebar
            ? _c(
                "broker-sidebar",
                [_c("broker-contact", { attrs: { content: _vm.contact } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }