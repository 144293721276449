<template>
  <div
    :class="['sidebar', {
      'sidebar--with-background': background,
      'sidebar--with-border': border,
      'sidebar--width-40': parseInt(width, 10) === 40
    }]"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'BrokerSidebar',
    props: {
      background: {
        type: Boolean,
        required: false,
        default: false
      },
      border: {
        type: Boolean,
        required: false,
        default: false
      },
      width: {
        type: [Number, String, Boolean],
        required: false,
        default: null
      }
    }
  }
</script>

<style lang="scss" scoped>
  .sidebar {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;

    @include mq(medium) {
      width: calc(100% / 3 - #{rhythm(small)});
      margin-left: rhythm(large);
    }
  }

  .sidebar--with-border {
    border-bottom: 1px solid;
  }

  .sidebar--with-background {
    padding: rhythm();
    background-color: #f2f2f2;

    &.sidebar--with-border {
      border-color: #fff;
    }
  }

  .sidebar--width-40 {
    @include mq(medium) {
      width: 40%;
    }
  }
</style>
