var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contact" }, [
    _c("h4", [_vm._v(_vm._s(_vm.content.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "row row--center" }, [
      _c(
        "span",
        { staticClass: "icon" },
        [_c("zg-icon", { attrs: { icon: "phone" } })],
        1
      ),
      _vm._v(" "),
      _c("strong", [_vm._v(_vm._s(_vm.content.phone.label))]),
      _vm._v(" "),
      _c("a", { attrs: { href: `tel:${_vm.phone}` } }, [
        _vm._v(_vm._s(_vm.content.phone.value)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row row--center" }, [
      _c(
        "span",
        { staticClass: "icon" },
        [_c("zg-icon", { attrs: { icon: "send" } })],
        1
      ),
      _vm._v(" "),
      _c("strong", [_vm._v(_vm._s(_vm.content.email.label))]),
      _vm._v(" "),
      _c("a", { attrs: { href: `mailto:${_vm.content.email.value}` } }, [
        _vm._v(_vm._s(_vm.content.email.value)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("span", { staticClass: "icon icon--filler" }),
      _vm._v(" "),
      _c("strong", [_vm._v(_vm._s(_vm.content.hours.label))]),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.content.hours.value) } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("span", { staticClass: "icon icon--filler" }),
      _vm._v(" "),
      _c("strong", [_vm._v(_vm._s(_vm.content.postal.label))]),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.content.postal.value) } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }