var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "sidebar",
        {
          "sidebar--with-background": _vm.background,
          "sidebar--with-border": _vm.border,
          "sidebar--width-40": parseInt(_vm.width, 10) === 40,
        },
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }