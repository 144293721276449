<template>
  <zg-section
    background="white"
    width="xl"
    extra-y-padding
  >
    <div
      :class="['faq', {
        'faq--with-sidebar': sidebar
      }]"
    >
      <div class="questions">
        <h3>{{ title }}</h3>
        <zg-faq :faqs="faqs" />
      </div>

      <broker-sidebar v-if="sidebar">
        <broker-contact :content="contact" />
      </broker-sidebar>
    </div>
  </zg-section>
</template>

<script>
  import BrokerContact from './BrokerContact'
  import BrokerSidebar from './BrokerSidebar'

  export default {
    name: 'BrokerFAQ',
    components: {
      BrokerSidebar,
      BrokerContact,
      ZgFaq: () => import(/* webpackChunkName: 'zc/zg-faq' */
        '@zc/components/ZgFaq/ZgFaq.vue'),
      ZgSection: () => import(/* webpackChunkName: 'zc/zg-section' */
        '@zc/components/ZgSection/ZgSection.vue')
    },
    props: {
      title: {
        type: String,
        required: false,
        default: null
      },
      body: {
        type: Array,
        required: false,
        default: null
      },
      sidebar: {
        type: Boolean,
        required: false,
        default: true
      },
      contact: {
        type: Object,
        required: false,
        default: () => {}
      }
    },
    computed: {
      faqs () {
        return this.body?.[0]?.value?.faqs
      }
    }
  }
</script>

<style lang="scss" scoped>
  .faq {
    display: flex;
    flex-direction: column;

    @include mq(medium) {
      flex-direction: row;
    }

    & > div:first-child {
      flex-grow: 1;
    }

    ::v-deep a {
      color: #24385b;
      border-color: #24385b;
      font-weight: bold;
    }
  }
</style>
