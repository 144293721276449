<template>
  <div class="contact">
    <h4>{{ content.title }}</h4>

    <div class="row row--center">
      <span class="icon">
        <zg-icon icon="phone" />
      </span>

      <strong>{{ content.phone.label }}</strong>
      <a :href="`tel:${phone}`">{{ content.phone.value }}</a>
    </div>

    <div class="row row--center">
      <span class="icon">
        <zg-icon icon="send" />
      </span>

      <strong>{{ content.email.label }}</strong>
      <a :href="`mailto:${content.email.value}`">{{ content.email.value }}</a>
    </div>

    <div class="row">
      <span class="icon icon--filler" />

      <strong>{{ content.hours.label }}</strong>
      <span v-html="content.hours.value" />
    </div>

    <div class="row">
      <span class="icon icon--filler" />

      <strong>{{ content.postal.label }}</strong>
      <span v-html="content.postal.value" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BrokerContact',
    components: {
      ZgIcon: () => import(/* webpackChunkName: 'zc/zg-icon' */
        '@zc/components/ZgIcon/ZgIcon.vue')
    },
    props: {
      content: {
        type: Object,
        required: true
      }
    },
    computed: {
      phone () {
        return this.content?.phone?.value?.replace(/[^0-9-]/g, '')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .contact {
    background-color: #f2f2f2;
    padding: rhythm() rhythm(small);
    margin: rhythm() (- rhythm(small)) 0;
    border-radius: radius(box);

    @include mq(medium) {
      padding: rhythm();
      margin: 0;
    }
  }

  .row {
    display: flex;
    margin: 0 0 rhythm(small);

    &:last-child {
      margin: 0;
    }

    &--center {
      align-items: center;
    }

    .icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: rem(20px);
      height: rem(20px);
      background-color: #24385b;
      margin-right: rhythm(xsmall);
      padding: rhythm(0.5);
      border-radius: 100%;

      &--filler {
        background-color: transparent;
      }

      ::v-deep svg {
        width: 100%;
        fill: #fff;
      }
    }

    strong {
      margin-right: rhythm(0.5);
    }

    a {
      color: #24385b;
      border-color: #24385b;
      font-weight: bold;
    }
  }
</style>
